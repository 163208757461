import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select,
    Checkbox2,
    FieldFileInput
} from './fields'

import loader from '../../assets/img/Ripple-1.4s-231px.gif'

import scrollToFirstError from './SmoothScrollToErrorFields.js';

class CompetitionPiattoForm extends React.Component {

    constructor(props) {
        super(props);
        this.upload = React.createRef();

        this.state= {
            button: 'Send',
            upload: null
        }
    }

    

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-message-alert" key={index}>{item[0]}</div>
            )
        })
    }

    renderShops = () => {
        const shops = [
          'Eastgate',
          'Stoneridge',
          'Kempton',
          'Middelburg',
          'Alberton',
          'Faramere',
          'Midrand',
          'Mall of the South',          
        ]

        return shops.map((item, index) => {
            return <option key={index}>{item}</option>
        })
    }

    //displays the upload below the input field and resets the drawing pad
    handleImageUpload = async data => {
      await this.setState({ upload: data });
      this.props.change("image", data);
      this.upload.current.src = URL.createObjectURL(data);
    };

    renderSubmitButton = () => {
        if(this.state.button === 'Sending'){
            return <img src={loader} alt="Sending" width="60" />
        } else {
            return <button className={`_button _submit-button`} type="submit">Submit</button>
        }
    }

    onSubmit = formValues => {

        this.setState({button: 'Sending'})

        const data = new FormData()

        formValues.shop && data.append('shop', formValues.shop)
        formValues.waiter && data.append('waiter', formValues.waiter)
        formValues.image && data.append('image', formValues.image)
        formValues.name && data.append('name', formValues.name)
        formValues.surname && data.append('surname', formValues.surname)
        formValues.email && data.append('email', formValues.email)
        formValues.phone && data.append('phone', formValues.phone)
        formValues.opt_in && data.append('opt_in', formValues.opt_in)
        
        this.props.onSubmit(data)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-contact">

                <h4 className="_heading-3 _form-heading">Complete the form below.</h4>
                {'shop' && <div name={`position-shop`} />}
                {'waiter' && <div name={`position-waiter`} />}
                <div className="_form-row">
                    <Field name="shop" component={Select} label="Shop*">
                        <option value="">Please select a the shop</option>
                        {this.renderShops()}
                    </Field>
                    <Field name="waiter" type="text" component={Input} label="Waiter's Name*" />
                </div>
                {'image' && <div name={`position-image`} />}
                <h4 className="_heading-3 _form-heading">Take a photo of the wine or your bill.</h4>
                <div className="" style={{textAlign: 'center'}}>

                    <Field
                        onChange={this.handleImageUpload}
                        name="image"
                        inputName="image"
                        type="file"
                        component={FieldFileInput}
                        label="Click here to take photo or upload image*"
                    />
                    {this.state.upload && (
                        <div className="">
                            <p style={{color: 'green', fontSize: '16px'}}>Image uploaded &#10003;</p>
                            <img
                                ref={this.upload}
                                style={{
                                    border: "3px solid #ccc",
                                    marginTop: "5px",
                                    maxWidth: "300px",
                                    width: "100%",
                                }}
                                src=""
                                id="upload"
                                alt=""
                            />
                        </div>
                    )}
                </div>
                

                <h4 className="_heading-3 _form-heading">Customer's Information.</h4>
                {'name' && <div name={`position-name`} />}
                {'surname' && <div name={`position-surname`} />}
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name*" />
                    <Field name="surname" type="text" component={Input} label="Surname*" />
                </div>
                {'email' && <div name={`position-email`} />}
                {'phone' && <div name={`position-phone`} />}
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address*" />
                    <Field name="phone" type="text" component={Input} label="Mobile Number*" />
                </div>

                <h4 className="_heading-3 _form-headingv">Opt in for Communications.</h4>
                <div className="_form-row">
                    <Field name="opt_in" component={Checkbox2} label="Check this box to Opt In (optional)" />
                </div>

                <h4 className="_heading-3 _form-heading">Terms & Conditions:</h4>
                {'over_18' && <div name={`position-over_18`} />}
                {'terms_accepted' && <div name={`position-terms_accepted`} />}
                <div className="_form-row">
                    <Field name="over_18" component={Checkbox2} label="I am over 18*" />
                    <Field name="terms_accepted" component={Checkbox2} label="Accept Terms & Conditions*" link="https://www.fatbastardwine.co.za/terms-and-conditions" />
                </div>

                {
                    this.props.errors && (
                        <div className="_form-row">
                            <div className="_error-group">{this.renderErrors()}</div>
                        </div>
                    )
                }

                <div className="_form-group" style={{marginTop: '0', textAlign: 'center'}}>
                    {this.renderSubmitButton()}
                </div>
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.shop)
    errors.shop = 'You must select a shop'

    if(!formValues.waiter)
    errors.waiter = 'You must enter the name of your waiter'

    if(!formValues.image)
    errors.image = 'You must upload an image'

    if(!formValues.name)
    errors.name = "You must enter your name"    

    if(!formValues.surname)
    errors.surname = "You must enter your surname"    

    if(!formValues.email)
    errors.email = "You must enter your email address"    

    if(!formValues.phone)
    errors.phone = "You must enter your mobile number"    

    if(!formValues.over_18)
    errors.over_18 = "You must confirm that you are over 18 years old"    

    if(!formValues.terms_accepted)
    errors.terms_accepted = "You accept our terms and conditions"    

    return errors
}

export default reduxForm({
    form: 'piattoform',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(CompetitionPiattoForm)