import React from 'react'

const renderError = ({error, touched}) => {
    if(error && touched){
        return (
            <small className="_error-message">
                {error}
            </small>
        )
    }
}

export const Input = ({input, label, placeholder, meta, type, className}) => {
    return(
        <div className={`_form-group ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`}>
            <label>{label ? label : <br />}</label>
            <input className="_form-element" {...input} type={type} placeholder={placeholder ? placeholder : ""} min="1" />
            {renderError(meta)}
        </div>
    )
} 

export const Checkbox = ({input, label, meta, className, checked, link}) => {
    return(
        <div className={`_form-group-checkbox ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`}>
            <div className="_checkbox-row">
                <input className="_form-element" {...input} type="checkbox" defaultChecked={checked} />
                {link && (
                    <a href={link} target="_blank" rel="noreferrer">
                        <label>{label ? label : <br />}</label>
                    </a>
                )}
                {!link && (
                    <label>{label ? label : <br />}</label>
                )}
            </div>
            {renderError(meta)}
        </div>
    )
}

export const Checkbox2 = ({input, label, meta, className, checked, link}) => {
    return(
        <div className={`_form-group-checkbox _checkbox-2 ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`}>
            <div className="_checkbox-row">
                <div className="_item">
                    <label>
                        <input {...input} type="checkbox" defaultChecked={checked} />
                        <span className="_checkbox"></span>
                    </label>
                    {link && (
                    <a href={link} target="_blank" rel="noreferrer">
                        <p style={{textDecoration: 'underline'}}>{label ? label : <br />}</p>
                    </a>
                    )}
                    {!link && (
                        <p>{label ? label : <br />}</p>
                    )}
                </div>
            </div>
            {renderError(meta)}
        </div>
    )
}

export const Select = ({input, label, placeholder, meta, children, className}) => {
    return (
        <div className={`_form-group ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`} >
            {label && <label>{label}</label>}
            <select className="_form-element" {...input} placeholder={placeholder ? placeholder : ""}>
                {children}
            </select>
            {renderError(meta)}
        </div>
    )
}

export const TextArea = ({input, label, placeholder, meta, className}) => {
    return (
        <div style={{width: '100%'}} className={`_form-group  ${className ? className : ''} ${meta.error && meta.touched ? '_error' : ''}`} >
            {label && <label>{label}</label>}
            <textarea className="_form-element" rows="10" {...input} autoComplete="true" placeholder={placeholder ? placeholder : ""} />
            {renderError(meta)}
        </div>
    )
}

export class FieldFileInput extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const {
        input: { onChange }
        } = this.props;
        onChange(e.target.files[0]);
    }

    render() {
        const { input, label, meta, inputName } = this.props; //whatever props you send to the component from redux-form Field
        return (
        <div>
            <div className="upload-btn-wrapper">
            <button className="upload-btn" type="button">{label}</button>
            <input
                name={inputName}
                type="file"
                accept=".jpg, .png, .jpeg"
                onChange={this.onChange}
            />
            </div>
            {renderError(meta)}
        </div>
        );
    }
}