import React from 'react'
import Layout from './layout/Layout'
import Helmet from 'react-helmet'
import ScrollToTop from '../ScrollToTop'
import AuthForm from './forms/AuthForm'
import {
  signIn
} from '../actions'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

class Signin extends React.Component {

  onSubmit = formValues => {
    this.props.signIn(formValues)
  }

  render(){

    if(this.props.auth.token){
      return <Redirect to="/dashboard" />
    }

    return (
      <>
        <Helmet>
            <title>Sign In | Template 1</title>
            <meta name="description" content="Description about this page." />
            <link rel="canonical" href={window.location.href} />
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <ScrollToTop />
        <Layout>
          <section className='_section _section-competition-form'>
            <div className='_grid'>
              <div className='_column'>   
                <div className='_panel'>
                  <h1 style={{marginTop: '60px'}}>Sign In</h1>
                  <p>Signin to the CMS Dashboard.</p> 
                  <AuthForm 
                      onSubmit={this.onSubmit} 
                      errors={this.props.auth.errors ? this.props.auth.errors : null}
                  />
                </div>
              </div>    
            </div>    
          </section>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      auth: state.authState
  }
}

export default connect(mapStateToProps, {
  signIn
})(Signin)