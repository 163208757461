export const RESET_FORMS = 'RESET_FORMS'
export const SUBMIT_COMPETITION_FORM = 'SUBMIT_COMPETITION_FORM'
export const SUBMIT_COMPETITION_FAILED = 'SUBMIT_COMPETITION_FAILED'

export const SUBMIT_COMPETITION_PIATTO_FORM = 'SUBMIT_COMPETITION_PIATTO_FORM'
export const SUBMIT_COMPETITION_PIATTO_FORM_FAILED = 'SUBMIT_COMPETITION_PIATTO_FORM_FAILED'

//admin
export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS_FAILES = 'UPDATE_ACCOUNT_DETAILS_FAILES'

export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'

export const FETCH_ENTRIES_PIATTO = 'FETCH_ENTRIES_PIATTO'
export const FETCH_ENTRY_PIATTO_DETAILS = 'FETCH_ENTRY_PIATTO_DETAILS'