import admin from '../apis/admin'

import {
    RESET_FORMS,
    SUBMIT_COMPETITION_FORM,
    SUBMIT_COMPETITION_FAILED,
    SUBMIT_COMPETITION_PIATTO_FORM,
    SUBMIT_COMPETITION_PIATTO_FORM_FAILED,
    SIGNIN,
    SIGNIN_FAILED,
    SIGNOUT,
    FETCH_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAILES,
    FETCH_USERS,
    CREATE_USER,
    CREATE_USER_FAILED,
    FETCH_USER,
    UPDATE_USER,
    UPDATE_USER_FAILED,
    DELETE_USER,
    FETCH_ENTRIES_PIATTO,
    FETCH_ENTRY_PIATTO_DETAILS
} from './types'

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const submitCompetitionForm = formValues => async dispatch => {
    try {

        const res = await admin.post('winter-wonderland', formValues)

        dispatch({
            type: SUBMIT_COMPETITION_FORM,
            payload: res.data
        })
        
    } catch (error) {
        dispatch({
            type: SUBMIT_COMPETITION_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const submitCompetitionPiattoForm = formValues => async dispatch => {
    try {

        const response = await admin.post('piatto', formValues)

        dispatch({
            type: SUBMIT_COMPETITION_PIATTO_FORM,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SUBMIT_COMPETITION_PIATTO_FORM_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signIn = formValues => async dispatch => {
    try {

        const response = await admin.post('login', formValues)

        dispatch({
            type: SIGNIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signOut = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/logout', {}, config)

        console.log('signed out')

        dispatch({
            type: SIGNOUT
        })
        
    } catch (error) {

        console.log('sign out failed')
        
        dispatch({
            type: SIGNOUT
        })
    }
}

export const fetchAccountDetails = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/account', config)

        dispatch({
            type: FETCH_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch account details failed')
    }
}

export const updateAccountDetails = (token, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/account/update', formValues, config)

        dispatch({
            type: UPDATE_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_ACCOUNT_DETAILS_FAILES,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUsers = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users', config)

        dispatch({
            type: FETCH_USERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching users failed')
    }
}

export const createUser = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/create', formValues, config)

        dispatch({
            type: CREATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users/'+id, config)

        dispatch({
            type: FETCH_USER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching user failed')
    }
}


export const updateUser = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/'+id+'/update', formValues, config)

        dispatch({
            type: UPDATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const deleteUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/users/'+id+'/delete', {}, config)

        dispatch({
            type: DELETE_USER,
            payload: id
        })
        
    } catch (error) {
        console.log('delete user failed')
    }
}

export const fetchEntriesPiatto = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/entries/piatto', config)

        dispatch({
            type: FETCH_ENTRIES_PIATTO,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching entries failed')
    }
}

export const fetchEntryPiattoDetails = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/entries/piatto/'+id, config)

        dispatch({
            type: FETCH_ENTRY_PIATTO_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching entry failed')
    }
}

