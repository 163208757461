import React from 'react'
import covid from '../../assets/img/covid.png'

class CovidBanner extends React.Component {
    render(){

        return(
            <section className='_section _section-covid-banner'>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_panel'>
                            <div>
                                <img className="_covid" src={covid} alt="" />
                            </div>
                            <p>For more information on COVID-19, visit <a href="www.sacoronavirus.co.za" target="_blank" rel="noreferrer" className="_gold">www.sacoronavirus.co.za</a></p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CovidBanner