import React from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import history from '../history'

import Competition from './Competition'
import PageNotFound from './PageNotFound'
// import Home from './Home'
import Thankyou from './Thankyou'
import CompetitionPiatto from './piatto/CompetitionPiatto'
import PiattoThankyou from './piatto/PiattoThankyou'
import Signin from './Signin'
import Dashboard from './dashboard/Dashboard'
import DashboardAccount from './dashboard/DashboardAccount'
import DashboardUsers from './dashboard/DashboardUsers'
import DashboardUserCreate from './dashboard/DashboardUserCreate'
import DashboardUserEdit from './dashboard/DashboardUserEdit'
import DashboardEntriesPiatto from './dashboard/DashboardEntriesPiatto'
import DashboardEntryPiattoDetails from './dashboard/DashboardEntryPiattoDetails'

class App extends React.Component {
    render(){
        return(
            <Router history={history}>
                <Switch>
                    {/* <Route exact path="/" component={Home} /> */}
                    {/* <Route exact path="/winter-winderland" component={Competition} />
                    <Route exact path="/winter-wonderland" component={Competition} />
                    <Route exact path="/winter-winderland/thankyou" component={Thankyou} /> */}
                    <Route exact path="/piatto" component={CompetitionPiatto} />
                    <Route exact path="/piatto/thankyou" component={PiattoThankyou} />
                    <Route exact path={["/signin", "/login", "/admin"]} component={Signin} />

                    {/* admin */}
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/account" component={DashboardAccount} />
                    <Route exact path="/dashboard/users" component={DashboardUsers} />
                    <Route exact path="/dashboard/users/create" component={DashboardUserCreate} />
                    <Route exact path="/dashboard/users/:userId/edit" component={DashboardUserEdit} />
                    <Route exact path="/dashboard/entries/piatto" component={DashboardEntriesPiatto} />
                    <Route exact path="/dashboard/entries/piatto/:entryId/details" component={DashboardEntryPiattoDetails} />
                    
                    <Route exact component={PageNotFound} /> 
                </Switch>
            </Router>
        )
    }
}

export default App