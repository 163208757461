import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchEntryPiattoDetails
} from '../../actions'
import {BASE_PATH} from '../../paths'
import {formatDate} from '../../utils'

class DashboardEntryPiattoDetails extends React.Component {

    state = {
        fullScreen: false
    }

    componentDidMount(){
        this.props.fetchEntryPiattoDetails(this.props.token, this.props.match.params.entryId)
    }

    toggleImagePosition = () => {
        this.setState({fullScreen: !this.state.fullScreen})
    }

    render(){

        if(!this.props.data)
        return

        const {name, surname, email, phone, shop, waiter, image, opt_in, created_at} = this.props.data

        return(
            <DashboardLayout 
                heading="Piatto Entries Details" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/entries/piatto">Entries / Piatto</Link> 
                </>)}
            >
                <div className='_button-group'>
                    <Link to="/dashboard/entries/piatto" className='_button _button-2'>&#10094;&nbsp; Back</Link>
                </div>

                <table className='_dashboard-table' style={{marginTop: '30px'}}>
                    <tbody>
                        <tr>
                            <td>Full Name</td>
                            <td>{name} {surname}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{email}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{phone}</td>
                        </tr>
                        <tr>
                            <td>Shop</td>
                            <td>{shop}</td>
                        </tr>
                        <tr>
                            <td>Waiter's Name</td>
                            <td>{waiter}</td>
                        </tr>
                        <tr>
                            <td>Opt In</td>
                            <td>{opt_in}</td>
                        </tr>
                        <tr>
                            <td>Image Uploaded</td>
                            <td>
                                <img src={BASE_PATH+image} alt="" onClick={this.toggleImagePosition} className={this.state.fullScreen ? '_fullscreen' : ''} style={{cursor:'pointer'}} />
                            </td>
                        </tr>
                        <tr>
                            <td>Date of entry</td>
                            <td>{formatDate(created_at)}</td>
                        </tr>
                    </tbody>
                </table>
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        data: state.entriesPiattoState[ownProps.match.params.entryId]
    }
}

export default connect(mapStateToProps, {
    fetchEntryPiattoDetails
})(DashboardEntryPiattoDetails)