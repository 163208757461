import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout/Layout'
import logo from '../../assets/img/entry-page/fb-logo@2x.png'
import hero from '../../assets/img/emerald-casino-hero@2x.jpg'
import heroCropped from '../../assets/img/emerald-casino-hero-cropped@2x.jpg'
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
  } from "react-share";

class PiattoThankyou extends React.Component {
    
    render(){

        return(
            <Layout>
                <Helmet>
                    <meta name="description" content="Stand to WIN a 2 night stay for 2 people including Bed & Breakfast at Emerald Casino." />
                    <title>Piatto | FAT bastard Wines</title>

                    <meta property="og:image:height" content="430" />
                    <meta property="og:image:width" content="821" />
                    <meta property="og:title" content="FAT bastard Wines Campaign" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://campaign.fatbastardwine.co.za/emerald-casino-hero@2x.jpg" />
                    <meta property="og:url" content="https://campaign.fatbastardwine.co.za/piatto" />

                    <meta property="og:description" content="Stand to WIN a 2 night stay for 2 people including Bed & Breakfast at Emerald Casino." />
                    <meta property="og:site_name" content="FAT bastard Wines Campaign" />
                    <meta name="twitter:image:alt" content="FAT bastard Wines Piatto Campaign" />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <section className='_section _section-1'>
                    <div className='_grid _grid-2'>
                        <div className='_column'>
                            <div className='_panel'>
                                <img src={logo} alt="" className='_logo' /><br />
                                <h1 className='_heading-1'>Thank You!</h1>
                                <h3 className='_heading-2'>You have successfully entered the competition!</h3>
                                
                                <h3 className='_heading-2' style={{marginBottom: '0'}}>The competition closes on 30 August 2022.</h3>
                                <p><a style={{textDecoration: 'underline'}} href="https://www.fatbastardwine.co.za/terms-and-conditions" target="_blank" rel="noreferrer">View the Terms & Conditions</a></p>
                                <h4 className='_explore-text' style={{marginTop: '20px'}}><a style={{textDecoration: 'underline'}} href="https://www.fatbastardwine.co.za/" target="_blank" rel="noreferrer">Explore Our Website!</a></h4>
                            </div>
                        </div>
                        
                    </div>
                    <img src={hero} alt="" className='_heroImg' />
                </section>
            </Layout>
        )
    }
}

export default PiattoThankyou