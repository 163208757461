import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../../assets/img/entry-page/fb-logo@2x.png'

class Logo extends React.Component {

    render(){

        return(
            <Link to="/dashboard" className='_logo'>
                <img src={logo} alt="" width="170" />
            </Link>
        )
    }
}

export default Logo