import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchEntriesPiatto
} from '../../actions'
import DataTable from 'react-data-table-component';
import {BASE_PATH} from '../../paths'
import {formatDate} from '../../utils'

class DashboardEntriesPiatto extends React.Component {

    componentDidMount(){
        this.props.fetchEntriesPiatto(this.props.token)
    }

    renderButtons = id => {
        return (
            <div className="_buttons-group">
                <Link className="_table-button _table-button-edit" to={`/dashboard/entries/piatto/${id}/details`} >View</Link>
            </div>
        )
    }

    downloadCsv = () => {

        const firstRow = ['ID', 'FULL NAME', 'EMAIL', 'PHONE', 'SHOP', `WAITER'S NAME`, 'OPT IN', 'IMAGE LINK', 'DATE']

        const rows = [firstRow]

        this.props.data.map(function({id, image, name, opt_in, phone, shop, surname, created_at, waiter, email}) {

            let row = [
                id.toString(),
                `${name} ${surname}`,
                email,
                phone,
                shop,
                waiter,
                opt_in,
                `${BASE_PATH}${image}`,
                formatDate(created_at),
            ]

            rows.push(row)
            return <></>
        })
        
        var csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);

        const now = new Date()
        const  timestamp = `${now.getDate()}_${now.getMonth() + 1}_${now.getFullYear()}`
        const fileName = `entries_${timestamp}.csv`;
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click(); 
    }

    render(){

        const columns = [
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'FULL NAME',
                selector: row => `${row.name} ${row.surname}`,
                sortable: true,
            },
            {
                name: 'EMAIL',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'PHONE',
                selector: row => row.phone,
                sortable: true,
            },
            {
                name: 'WAITER',
                selector: row => row.waiter,
                sortable: true,
            },
            {
                name: 'OPT IN',
                selector: row => row.opt_in,
                sortable: true,
            },
            {
                name: 'ENTERED',
                selector: row => formatDate(row.created_at),
                sortable: true,
            },
            {
                name: 'ACTIONS',
                selector: row => row.actions,
                cell: row => {
                    return this.renderButtons(row.id)
                }
            },
        ];

        const customStyles = {
            headCells: {    
                style: {    
                    color: '#306191',    
                    fontSize: '16px',
                    fontWeight: '600'    
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',   
                    outline: '1px solid #FFFFFF',   
                },   
                style: {       
                    fontSize: '16px',   
                },
            },    
            pagination: {
                style: {
                    border: 'none',
                },
            },
        };

        return(
            <DashboardLayout 
                heading="Piatto Entries" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / Entries / Piatto 
                </>)}
            >
                <div className='_button-group'>
                    <button className='_button _button-2' onClick={this.downloadCsv}>&#9660;&nbsp; Download CSV</button>
                </div>
                
                <DataTable
                    columns={columns}
                    data={this.props.data}
                    pagination
                    customStyles={customStyles}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: Object.values(state.entriesPiattoState)
    }
}

export default connect(mapStateToProps, {
    fetchEntriesPiatto
})(DashboardEntriesPiatto)