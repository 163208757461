export const formatDate = dateString => {

  let date = new Date(dateString)

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()
  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()

  let formattedDate = `${year}/${month}/${day} ${hour}:${minute}:${second}`

  return formattedDate
}