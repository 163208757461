import React from 'react'
import Layout from './layout/Layout'
import logo from '../assets/img/entry-page/fb-logo@2x.png'
import hero from '../assets/img/entry-page/winter-wonderland-hero@2x.png'
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
  } from "react-share";

class Thankyou extends React.Component {
    
    render(){

        return(
            <Layout>
                <section className='_section _section-1'>
                    <div className='_grid _grid-2'>
                        <div className='_column'>
                            <div className='_panel'>
                                <img src={logo} alt="" className='_logo' /><br />
                                <h1 className='_heading-1'>Thank You!</h1>
                                <h3 className='_heading-2'>Share the competition on social media & get another entry.</h3>
                                <div className='_social-share'>
                                    <FacebookShareButton 
                                        url={window.location}
                                        quote="Enter my WINter WINderland for your chance to WIN 1 of 5 FAT bastard Hampers, including a stylish Fieldbar Cooler and a case of Fb Chardonnay worth R2,200. Sign up here for your chance to live a little LARGER! Competition ends 31 July 2022. T’s & C’s apply."
                                        hashtag="#WINterWINderland"
                                    >
                                        <FacebookIcon size={45} round iconFillColor="#ffffff" bgStyle={{fill: '#D6B052'}} />
                                    </FacebookShareButton>
                                    <TwitterShareButton 
                                        url={window.location}
                                        title="WINter WINderland By Fat bastard"
                                        hashtags={[`WINterWINderland`, `LIVElarge`]}
                                    >
                                        <TwitterIcon size={45} round iconFillColor="#ffffff" bgStyle={{fill: '#D6B052'}} />
                                    </TwitterShareButton>
                                </div>
                                <h3 className='_heading-2' style={{marginBottom: '0'}}>The competition closes on 31 July 2022.</h3>
                                <p><a style={{textDecoration: 'underline'}} href="https://www.fatbastardwine.co.za/terms-and-conditions" target="_blank" rel="noreferrer">View the Terms & Conditions</a></p>
                                <h4 className='_explore-text' style={{marginTop: '20px'}}><a style={{textDecoration: 'underline'}} href="https://www.fatbastardwine.co.za/" target="_blank" rel="noreferrer">Explore Our Website!</a></h4>
                            </div>
                        </div>
                        
                    </div>
                    <img src={hero} alt="" className='_heroImg' />
                </section>
            </Layout>
        )
    }
}

export default Thankyou