import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Select,
    Checkbox2
} from './fields'

import loader from '../../assets/img/Ripple-1.4s-231px.gif'

import scrollToFirstError from './SmoothScrollToErrorFields.js';

class CompetitionForm extends React.Component {

    state= {
        button: 'Send'
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-message-alert" key={index}>{item[0]}</div>
            )
        })
    }

    renderProvinces = () => {
        const provinces = [
            'Western Province',
            'Eastern Cape',
            'Free State',
            'Gauteng',
            'KwaZulu-Natal',
            'Limpopo',
            'Mpumalanga',
            'Northern Cape',
            'North West',
        ]

        return provinces.map((item, index) => {
            return <option key={index}>{item}</option>
        })
    }

    renderSubmitButton = () => {
        if(this.state.button === 'Sending'){
            return <img src={loader} alt="Sending" width="60" />
        } else {
            return <button className={`_button _submit-button`} type="submit">Submit</button>
        }
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Sending'
        })
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-contact">
                <h4 className="_heading-3">Complete the form below.</h4>
                {'name' && <div name={`position-name`} />}
                {'surname' && <div name={`position-surname`} />}
                <div className="_form-row">
                    <Field name="name" type="text" component={Input} label="Name*" />
                    <Field name="surname" type="text" component={Input} label="Surname*" />
                </div>
                {'email' && <div name={`position-email`} />}
                {'phone' && <div name={`position-phone`} />}
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address*" />
                    <Field name="phone" type="text" component={Input} label="Mobile Number*" />
                </div>
                {'gender' && <div name={`position-gender`} />}
                {'birthday' && <div name={`position-birthday`} />}
                <div className="_form-row">
                    <Field name="gender" component={Select} label="Gender*">
                        <option value="">Please select your gender</option>
                        <option>Male</option>
                        <option>Female</option>
                    </Field>
                    <Field name="birthday" type="date" component={Input} label="Birthday*" />
                </div>
                {'country' && <div name={`position-country`} />}
                {'province' && <div name={`position-province`} />}
                <div className="_form-row">
                    <Field name="country" type="text" component={Select} label="Country*">
                        <option value="">Please select your country</option>
                        <option>South Africa</option>
                    </Field>
                    <Field name="province" component={Select} label="Province*">
                        <option value="">Please select your province</option>
                        {this.renderProvinces()}
                    </Field>
                </div>
                {'city' && <div name={`position-city`} />}
                <div className="_form-row">
                    <Field name="city" type="text" component={Input} label="City*" />
                </div>

                <h4 className="_heading-3">Which <strong>FAT <i>bastard</i></strong> wine is your favourite?</h4>

                <div className="_form-row">
                    <Field name="red" component={Checkbox2} label="Red" />
                    <Field name="white" component={Checkbox2} label="White" />
                    <Field name="rose" component={Checkbox2} label="Rosé" />
                </div>

                <h4 className="_heading-3">Which social media channels do you use on a regular basis?</h4>

                <div className="_form-row">
                    <Field name="facebook" component={Checkbox2} label="Facebook" />
                    <Field name="twitter" component={Checkbox2} label="Twitter" />
                    <Field name="instagram" component={Checkbox2} label="Instagram" />
                    <Field name="youtube" component={Checkbox2} label="YouTube" />
                </div>

                <div className="_form-row">
                    <Field name="whatsapp" component={Checkbox2} label="WhatsApp" />
                    <Field name="pinterest" component={Checkbox2} label="Pinterest" />
                    <Field name="wechat" component={Checkbox2} label="WeChat" />
                    <Field name="snapchat" component={Checkbox2} label="Snap Chat" />
                </div>

                <div className="_form-row">
                    <Field name="no_social" component={Checkbox2} label="I don’t use social media" />
                </div>

                <h4 className="_heading-3">Yes, I would like to receive <strong>FAT <i>bastard</i></strong> updates via:</h4>

                <div className="_form-row">
                    <Field name="recieve_updates_via_email" component={Checkbox2} label="Email" />
                    <Field name="recieve_updates_via_sms" component={Checkbox2} label="SMS" />
                </div>

                <h4 className="_heading-3">Terms & Conditions:</h4>
                {'over_18' && <div name={`position-over_18`} />}
                {'terms_accepted' && <div name={`position-terms_accepted`} />}
                <div className="_form-row">
                    <Field name="over_18" component={Checkbox2} label="I am over 18*" />
                    <Field name="terms_accepted" component={Checkbox2} label="Accept Terms & Conditions*" link="https://www.fatbastardwine.co.za/terms-and-conditions" />
                </div>

                {
                    this.props.errors && (
                        <div className="_form-row">
                            <div className="_error-group">{this.renderErrors()}</div>
                        </div>
                    )
                }

                <div className="_form-group" style={{marginTop: '0', textAlign: 'center'}}>
                    {this.renderSubmitButton()}
                </div>
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "You must enter your name"
    }

    if(!formValues.surname){
        errors.surname = "You must enter your surname"
    }

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.phone){
        errors.phone = "You must enter your mobile number"
    }

    if(!formValues.gender){
        errors.gender = "You must select your gender"
    }

    if(!formValues.birthday){
        errors.birthday = "You must enter your birthday"
    }

    if(!formValues.country){
        errors.country = "You must select your country"
    }

    if(!formValues.province){
        errors.province = "You must select your province"
    }

    if(!formValues.city){
        errors.city = "You must enter your city of residence"
    }

    if(!formValues.over_18){
        errors.over_18 = "You must confirm that you are over 18 years old"
    }

    if(!formValues.terms_accepted){
        errors.terms_accepted = "You accept our terms and conditions"
    }

    return errors
}

export default reduxForm({
    form: 'competition',
    validate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(CompetitionForm)