import React from 'react'
import Footer from './Footer'
import CovidBanner from './CovidBanner'
import ScrollToTop from '../../ScrollToTop'

class Layout extends React.Component {
    render(){
        return(
            <>
                <ScrollToTop /> 
                {this.props.children}
                <Footer />
                <CovidBanner />
            </>
        )
    }
}

export default Layout