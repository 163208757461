import React from 'react'
import logo from '../../assets/img/Fat-Bastard-Logo-White@2x.png'
import SocialNavList from '../ui/SocialNavList'
import awaredog from '../../assets/img/aware-logo-white-web.png'

class Footer extends React.Component {
    render(){

        const date = new Date()
        const year = date.getFullYear()
        return(
            <footer>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_panel'>
                            <img src={logo} alt="" className='_footer-logo' />
                            <ul className='_footer-nav-list'>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/" target="_blank" rel="noreferrer">Home</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/the-fat-bastards" target="_blank" rel="noreferrer">The FAT <i>bastards</i></a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/#vib" target="_blank" rel="noreferrer">VIB</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/fat-inspirations" target="_blank" rel="noreferrer">FAT <i>inspirations</i></a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/social-feed" target="_blank" rel="noreferrer">Social Feed</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/contact-us" target="_blank" rel="noreferrer">Get In Touch</a>
                                </li>
                            </ul>
                            <div className='_footer-horline-social-media'>
                                <div className='_horline' />
                                <SocialNavList />
                                <div className='_horline' />
                            </div>
                            <ul className='_footer-nav-list'>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/" target="_blank" rel="noreferrer">Copyrights © {year} FAT <i>bastard</i></a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.optimalonline.co.za/" target="_blank" rel="noreferrer">Powered by Optimal Online</a>
                                </li>
                                <li>|</li>
                                <li>
                                    All rights reserved
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>
                                </li>
                                <li>|</li>
                                <li>
                                    <a href="https://www.fatbastardwine.co.za/resources/PRIVACY-POLICY-ROBERTSON-WINERY.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </li>
                            </ul>
                            <img src={awaredog} alt="" className='_awaredog' />
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer