import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchUser,
    resetForms,
    updateUser
} from '../../actions'
import Form from '../forms/UserEditForm'

class DashboardUserEdit extends React.Component {

    componentDidMount(){
        this.props.fetchUser(this.props.token, this.props.match.params.userId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateUser(this.props.token, this.props.match.params.userId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/users" />
        }

        return(
            <DashboardLayout 
                heading="Edit User" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/users">Users</Link> / {this.props.match.params.userId} / Edit
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.usersState[ownProps.match.params.userId],
        data: state.userUpdateState
    }
}

export default connect(mapStateToProps, {
    fetchUser,
    updateUser,
    resetForms
})(DashboardUserEdit)