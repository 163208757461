import {
    SUBMIT_COMPETITION_PIATTO_FORM,
    SUBMIT_COMPETITION_PIATTO_FORM_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {
    success: null,
    errors: null
}

const submitCompetitionPiattoReducer = (state = initialState, action) => {
    switch (action.type){
        case SUBMIT_COMPETITION_PIATTO_FORM:
            return {
                success: true,
                errors: null
            }
        case SUBMIT_COMPETITION_PIATTO_FORM_FAILED:
            return {
                success: null,
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default submitCompetitionPiattoReducer