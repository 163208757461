import React from 'react'

class MenuButton extends React.Component{
    render(){
        return(
            <button className='_menu-button' onClick={this.props.onClick} aria-label="menu">
                Menu
            </button>
        )
    }
}

export default MenuButton