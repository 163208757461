import React from 'react'

class PageNotFound extends React.Component {
    render(){
        return(
            <h1>PageNotFound</h1>
        )
    }
}

export default PageNotFound