import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout/Layout'
import Form from '../forms/CompetitionPiattoForm'
import {Redirect} from 'react-router-dom'
import {
    submitCompetitionPiattoForm,
    resetForms
} from '../../actions'
import {connect} from 'react-redux'

import hero from '../../assets/img/emerald-casino-hero@2x.jpg'
import heroCropped from '../../assets/img/emerald-casino-hero-cropped@2x.jpg'
import logo from '../../assets/img/entry-page/fb-logo@2x.png'

class CompetitionPiatto extends React.Component {

    scrollToPosition = (anchorTag) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 0 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 100);
    }

    onSubmit = formValues => {
        this.props.submitCompetitionPiattoForm(formValues)
    }

    render(){

        if(!this.props.submitState.success)
        return(
            <Layout>
                <Helmet>
                    <meta name="description" content="Stand to WIN a 2 night stay for 2 people including Bed & Breakfast at Emerald Casino." />
                    <title>Piatto | FAT bastard Wines</title>

                    <meta property="og:image:height" content="430" />
                    <meta property="og:image:width" content="821" />
                    <meta property="og:title" content="FAT bastard Wines Campaign" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://campaign.fatbastardwine.co.za/emerald-casino-hero@2x.jpg" />
                    <meta property="og:url" content="https://campaign.fatbastardwine.co.za/piatto" />

                    <meta property="og:description" content="Stand to WIN a 2 night stay for 2 people including Bed & Breakfast at Emerald Casino." />
                    <meta property="og:site_name" content="FAT bastard Wines Campaign" />
                    <meta name="twitter:image:alt" content="FAT bastard Wines Piatto Campaign" />
                    <link rel="canonical" href="https://campaign.fatbastardwine.co.za/piatto" />
                </Helmet>
                <section className='_section _section-1 piatto'>
                    <img src={hero} alt="" className='_heroImg' />
                    <div className='_grid _grid-2'>
                        <div className='_column'>
                            <div className='_panel'>
                                <img src={logo} alt="" className='_logo' /><br />
                                <h1 className='_heading-1' style={{fontWeight: '600',marginTop: '50px'}}><span className='_special-heading'>Stand to WIN</span><br />
                                a 2 night stay for 2 people including Bed & Breakfast at Emerald Casino.</h1>
                                <p>Complete the form below and stand a chance to WIN.</p>
                                <button className='_button' onClick={() => this.scrollToPosition("#competition-form")} style={{marginTop: '50px'}}>Enter</button>
                            </div>
                        </div>
                    </div>
                    <img src={heroCropped} alt="" className='_heroImg-cropped' />
                </section>
                <section className='_section _section-competition-form piatto-form' id="competition-form">
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <Form 
                                    onSubmit={this.onSubmit}
                                    errors={this.props.submitState.errors}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )

        return <Redirect to="/piatto/thankyou" />
    }
}

const mapStateToProps = state => {
    return {
        submitState: state.submitCompetitionPiattoState
    }
}

export default connect(mapStateToProps, {
    submitCompetitionPiattoForm,
    resetForms
})(CompetitionPiatto)