import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import submitCompetitionReducer from './submitCompetitionReducer'
import submitCompetitionPiattoReducer from './submitCompetitionPiattoReducer'
import authReducer from './authReducer'
import userCreateReducer from './userCreateReducer'
import userUpdateReducer from './userUpdateReducer'
import usersReducer from './usersReducer'
import accountReducer from './accountReducer'
import accountUpdateReducer from './accountUpdateReducer'
import entriesPiattoReducer from './entriesPiattoReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState']
}

const rootReducer = combineReducers({
    submitCompetitionState: submitCompetitionReducer,
    submitCompetitionPiattoState: submitCompetitionPiattoReducer,
    authState: authReducer,
    accountState: accountReducer,
    accountUpdateState: accountUpdateReducer,
    userUpdateState: userUpdateReducer,
    userCreateState: userCreateReducer,
    usersState: usersReducer,
    entriesPiattoState: entriesPiattoReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)