import React from 'react'
import Form from './forms/CompetitionForm'
import {
    submitCompetitionForm,
    resetForms
} from '../actions'
import {connect} from 'react-redux'
import {
    Redirect
} from 'react-router-dom'
import Layout from './layout/Layout'
import logo from '../assets/img/entry-page/fb-logo@2x.png'
import winter from '../assets/img/entry-page/winter-wonderland@2x.png'
import hero from '../assets/img/entry-page/winter-wonderland-hero@2x.png'
import fieldbar from '../assets/img/entry-page/fieldbar-lifestyle@2x.png'
import fieldbarBen from '../assets/img/entry-page/fieldbar-benefits@2x.png'

class Competition extends React.Component {

    componentDidUpdate(){
        if(this.props.submitState.errors){
            setTimeout(() => {
                this.props.resetForms()
            }, 7000)
        }
    }

    scrollToPosition = (anchorTag) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 0 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 100);
    }

    onSubmit = formValues => {
        this.props.submitCompetitionForm(formValues)
    }
    
    render(){

        if(this.props.submitState.success){
            return <Redirect to="/winter-winderland/thankyou" />
        }

        return(
            <Layout>
                <section className='_section _section-1'>
                    <img src={hero} alt="" className='_heroImg' />
                    <div className='_grid _grid-2'>
                        <div className='_column'>
                            <div className='_panel'>
                                <img src={logo} alt="" className='_logo' /><br />
                                <img src={winter} alt="" className='_img-winter' />
                                <h1 className='_heading-1'>Trade the cold for <strong>GOLD</strong> and make this <strong>WIN</strong>ter a winner!</h1>
                                <button className='_button' onClick={() => this.scrollToPosition("#competition-form")}>Enter</button>
                                <button className='_scroll-down-btn' onClick={() => this.scrollToPosition("#fieldbar-section")}>
                                    <small>scroll down</small><div className='_line' />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='_section _section-2' id="fieldbar-section">
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <div className='_item'>
                                    <img src={fieldbar} alt="" className='_img-fieldbar' />
                                </div>
                                <div className='_item'>
                                    <div className='_inner-panel'>
                                        <h2 className='_heading-1'>Handcrafted Cooler Boxes</h2>
                                        <p>There was a time when they did outdoor adventure with style – with outdoor products that were elegant, durable and portable, and made by people who took pride in their work.</p>
                                        <h3 className='_heading-2'><i>Made in and inspired by Cape Town.</i></h3>
                                        <img src={fieldbarBen} alt="" className='_fieldbarBen' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='_section _section-competition-form' id="competition-form">
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <Form 
                                    onSubmit={this.onSubmit}
                                    errors={this.props.submitState.errors}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        submitState: state.submitCompetitionState
    }
}

export default connect(mapStateToProps, {
    submitCompetitionForm,
    resetForms
})(Competition)